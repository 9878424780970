import React, { useState } from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Input from "../components/Reusable/Input"
import Button from "../components/Reusable/Button"

import { openInNewTab } from "../services/utils"

const StyledForm = styled.form`
  padding-top: 50px;
  padding-bottom: 25px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 800px;
  margin: auto;

  h2 {
    padding-bottom: 25px;
    text-align: center;
  }

  .input-wrapper {
    padding-bottom: 12px;
  }

  button {
    width: 100%;
  }

  @media only screen and (max-width: 500px) {
    h2 {
      font-size: 1.5rem;
    }
  }
`

const MenjadiTutorPage = () => {
  const [myForm, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    medsos: "",
  })

  const inputChange = state => e => {
    const newForm = { ...myForm }
    newForm[state] = e.target.value
    setForm(newForm)
  }

  return (
    <Layout>
      <SEO title="Menjadi Tutor" />
      <StyledForm
        className="layout"
        onSubmit={e => {
          e.preventDefault()
          const text = `Name: ${myForm.name}%0D%0AEmail: ${myForm.email}%0D%0APhone: ${myForm.phone}%0D%0AMedia Sosial: ${myForm.medsos}`
          openInNewTab(`https://wa.me/6281383736364?text=${text}`)
        }}
      >
        <h2>Isi Form Berikut untuk Menjadi Tutor Purwalenta</h2>
        <p>
          <div className="input-wrapper">
            <Input
              className="input-name"
              type="text"
              name="name"
              placeholder="Nama Lengkap"
              label="Nama"
              onChange={inputChange("name")}
            />
          </div>
          <div className="input-wrapper">
            <Input
              className="input-email"
              type="text"
              name="email"
              placeholder="Email Anda"
              label="Email"
              onChange={inputChange("email")}
            />
          </div>
          <div className="input-wrapper">
            <Input
              className="input-phone"
              type="text"
              name="phone"
              placeholder="Nomor Telepon Anda"
              label="Nomor Telepon"
              onChange={inputChange("phone")}
            />
          </div>
          <div className="input-wrapper">
            <Input
              className="input-medsos"
              type="text"
              name="medsos"
              placeholder="Link ke Youtube / Twitter / Instagram / LinkedIn / Lainnya"
              label="Media sosial (Youtube/Instagram/lainnya)"
              onChange={inputChange("medsos")}
            />
          </div>
        </p>
        <p>
          <Button>Daftar</Button>
        </p>
      </StyledForm>
    </Layout>
  )
}

export default MenjadiTutorPage
