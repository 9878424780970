// Generic imports
import React, { forwardRef } from "react"
import styled from "styled-components"

// Other components imports

// Redux imports

// Static imports

// Utils imports

const Styles = styled.div`
  display: inline-block;
  width: 100%;
`

const Input = forwardRef((props, ref) => {
  const { label, id, ...otherProps } = props
  const inputId = id !== null ? id : props.name
  // const inputId = id

  return (
    <Styles className="taman-input">
      {label ? <label htmlFor={inputId}>{label}</label> : <></>}
      <input ref={ref} id={inputId} {...otherProps} />
    </Styles>
  )
})

Input.displayName = "Input"

export default Input
